import React from "react";

import { ScrollingProvider } from "../context/ScrollingContext";

import MainSection from "../sections/home/MainSection";
import AboutSection from "../sections/home/AboutSection";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";

const Home = () => {
    return (
        <>
            <ScrollingProvider>
                <MainSection />
                {/* <AboutSection /> */}
            </ScrollingProvider>

            <CoursesSection />
        </>
    );
};

export default Home;
