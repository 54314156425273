import React, { useContext, useEffect, useState } from "react";

import ScrollingContext from "../../context/ScrollingContext";

import { getScrollingPercentage, windowHeight } from "../../services/scrollingServices";

import Waves from "./Waves";

import "./sectionHead.css";

const SectionHead = ({ className = "bg-yellow-500 text-slate-100", title = "الكورسات" }) => {
    const [percentage, setPercentage] = useState(0);
    const { scrolling } = useContext(ScrollingContext);

    useEffect(() => {
        const yearsHeader = document.querySelector(".home-years__head");

        let { percentage: percent } = getScrollingPercentage(
            scrolling,
            yearsHeader.getBoundingClientRect().top + scrolling,
            (windowHeight + yearsHeader.style.height) / 2
        );
        setPercentage(percent);
    }, [scrolling]);

    return (
        <div className={`home-years__head ${className} mt-20`}>
            <div className="relative py-10 px-10 overflow-hidden">
                <div className="home-years__title font-big py-5 font-w-bold relative">
                    <div
                        className="home-years-title-bg__container"
                        style={{ transform: `translateX(${-100 * (1 - percentage)}%)` }}
                    >
                        <div className="home-years-title__bg font-bigmax text-slate-100">
                            {title}
                        </div>
                    </div>

                    <div className="home-years-title__content relative z-10">{title}</div>
                </div>
            </div>
            {/* <Waves className="fill-primary-container transform translate-y-1/4 scale-y-75 -mt-10" /> */}
            {/* <Waves className="fill-primary-container" /> */}
        </div>
    );
};

export default SectionHead;
