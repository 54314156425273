import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CenterIcon from "../../components/ui/CenterIcon";
import Container from "../../components/ui/Container";
import FlexRowReverse from "../../components/ui/FlexRowReverse";
import http from "../../services/httpServices";
import { description } from "../../utils/ar";

const PaymentInfoPage = () => {
    const [data, setData] = useState({
        name: "",
        description: "",
    });
    const getInfo = async () => {
        try {
            const { data: response } = await http.get("/api/payment_info");
            setData({ name: response.name, description: response.description });
        } catch (error) {}
    };
    useEffect(() => {
        getInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Container>
            <div className="relative z-10 bg-inner-container smooth clr-text-primary rounded-lg shadow-large p-10 -mt-52">
                <Link element="Link" className="underline clr-text-secondary smooth" to={-1}>
                    <FlexRowReverse>
                        <CenterIcon icon="akar-icons:arrow-right" />
                        <span>العودة للوراء</span>
                    </FlexRowReverse>
                </Link>
                <div className="font-h1 flex-center-both pb-10 font-w-bold">
                    <div className="text-center pt-5">طريقة الدفع و الإشتراك و بيانات الاتصال</div>
                </div>
                <div className="font-h3 py-7 font-w-bold">
                    {data.name} :
                    <br />
                </div>
                <div className="">
                    <br />
                    {/* {data.description} */}
                    {description(data.description)}
                </div>
            </div>
        </Container>
    );
};

export default PaymentInfoPage;
