import React from "react";

import logoTextDark from "../assets/navbar/logodark.png";
import logoTextLight from "../assets/navbar/logolight.png";
import NavbarLink from "../layout/navbar/components/NavbarLink";
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import CenterIcon from "../components/ui/CenterIcon";

export const navLogoComponent = (darkmode, isNavbarHidden) => (
    <>
        {/* <img
            className="h-12 w-auto block lg:hidden"
            src={logo}
            alt="Workflow"
        /> */}
        <img
            className="h-10 sm:h-14 w-auto md:mr-0 mr-6"
            src={darkmode ? logoTextDark : isNavbarHidden ? logoTextDark : logoTextLight}
            alt="Workflow"
        />
    </>
);
export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    `${isNavbarHidden && "bg-opacity-0 dark:bg-opacity-60 bg-zinc-100 dark:bg-zinc-900"} ${
        !isRTL ? "en" : ""
    } ${scrolling ? "bg-primary-container" : ""}`;

export const NavbarRegisterComponent = () => (
    <NavbarLink
        defualtHover="hover:bg-opacity-0 hover:text-yellow-500 group"
        to="/register"
        className="bg-yellow-500 clr-white border-2 border-yellow-500 group"
    >
        <span className="font-h2 flex-center-both text-yellow-600">
            <CenterIcon className="" icon="material-symbols:tab-new-right-outline-sharp" />
        </span>
        <span className="flex-center-both">انشئ حسابك !</span>
    </NavbarLink>
);
export const NavbarLoginComponent = ({ isRTL, isNavbarHidden }) => (
    <NavbarLink
        isClrPrimary={false}
        to="/login"
        className={`border border-emerald-400 border-opacity-0 ${
            !isNavbarHidden ? "clr-primary-text" : "text-slate-100"
        }`}
        defualtHover="hover:dark:border-opacity-100 hover-shadow"
    >
        <span className="font-h2 flex-center-both text-emerald-500">
            <CenterIcon className="pt-1" icon="fluent:arrow-join-20-filled" />
        </span>
        <span
            className={`flex-center-both space-x-1 space-x-reverse ${
                !isRTL ? "flex-row-reverse" : ""
            }`}
        >
            <span>تسجيل</span>
            <span className="text-emerald-400">الدخول</span>
        </span>
    </NavbarLink>
);
export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        <span className="font-h2 flex-center-both text-emerald-500">
            <CenterIcon className="pt-1" icon="fluent:arrow-join-20-filled" />
        </span>
        <span className="flex-center-both">الدخول إلى حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        <span className="font-h2 flex-center-both text-yellow-600">
            <CenterIcon className="" icon="material-symbols:tab-new-right-outline-sharp" />
        </span>
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <span className="flex-center-both">انشئ حسابك الآن !</span>
    </DisclosureLink>
);

export const domainName = "mohamed-fathy";
