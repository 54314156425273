import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "mohammed-fathy-admin";
export const domainName = "mohammed-fathy.com";
export const isManualPayment = true;
export const isCouponable = true;
export const isMultiYear = true;
export const isCodes = true;
export const isBtns = false;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "emerald";
export const headerSectionColor = "emerald";
export const navbarIconColor = "text-emerald-500";
export const navbarDisclosureColor = "emerald";

export const progressBarColor = "emerald";
export const loadingBarColor = "emerald";

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = true;
export const prepaidInfoColor = "emerald";

export const autoChangeRandomNames = true;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "emerald";
export const registerPageColor = "yellow";

export const isAnalytics = true;

export const isMultiAdmin = true;
