import React, { useContext, useState } from "react";
import Electric from "../../components/svgs/Electric";

import AuthContext from "../../context/AuthContext";

import "./MainSection.css";

import Button from "../../components/ui/Button";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
// import Waleed from "../../components/svgs/Waleed";
// import YearsBooks from "../../components/svgs/YearsBooks";
// import Shapes from "../../components/svgs/Shapes";
// import Waves from "../../components/ui/Waves";
import profile from "../../assets/profile.png";
import bgBottomLeft from "../../assets/mainbg.png";
const MainSection = ({ title = false }) => {
    const { token } = useContext(AuthContext);

    return (
        <>
            <section className="md:h-screen min-h-screen negative-nav-margin bg-main-color relative overflow-hidden">
                <img
                    className="bottom-0 left-0 max-w-3xl w-full z-0 absolute opacity-75 bg-pic-main"
                    src={bgBottomLeft}
                    alt="bg-bottom-left"
                />
                <Container className="h-full relative z-10 font-avn pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row-reverse flex-col h-full space-y-20 md:space-y-0">
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both">
                            <div className=" flex-center-both flex-col clr-white smooth space-y-6 md:space-y-0">
                                {/* <h2 className="font-h1 font-w-bold">منصة</h2> */}
                                <h1 className="font-bigmax font-w-bold relative">
                                    <span className="relative z-10">مسيو محمد فتحي</span>
                                </h1>
                                <div className=" flex-center-both flex-col font-sans-plain">
                                    <h2 className="font-h2 text-yellow-400">
                                        - مدرس <span className="text-yellow-600">لغة فرنسية</span>{" "}
                                        للمرحلة الثانوية العامة
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex-center-both max-w-md">
                                <img src={profile} alt={"profile"} className="" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
